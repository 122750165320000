














import { sdButton } from '@smartdesign/vue/components/button';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const valuation = namespace('valuation');
const tracking = namespace('tracking');
@Component({
  components: { sdButton }
})
export default class ValuationRequestButton extends Vue {
  @valuation.Action
  handleValuation!: () => void;

  @valuation.Getter
  getPhonenumberIsValid!: boolean;

  @tracking.Action
  trackLink!: (eventLabel: string) => void;

  private requestValuation(): void {
    this.handleValuation();
    this.trackLink('jetzt anfragen');
  }
}
