import { Theme } from './domain/theme.enum';
import { ThemeConfig } from './domain/theme-config.interface';

export class Utils {
  private static readonly themeConfigIw = {
    navigationPath: process.env.VUE_APP_NAVIGATION_IW,
    loginUrl: process.env.VUE_APP_LOGINURL_IW,
    valuationRangeUrl: process.env.VUE_APP_VALUATION_RANGE_URL_IW,
    mediaId: process.env.VUE_APP_MEDIA_ID_IW
  };

  private static readonly themeConfigIn = {
    navigationPath: process.env.VUE_APP_NAVIGATION_IN,
    loginUrl: process.env.VUE_APP_LOGINURL_IN,
    valuationRangeUrl: process.env.VUE_APP_VALUATION_RANGE_URL_IN,
    mediaId: process.env.VUE_APP_MEDIA_ID_IN
  };

  static themeConfigMap = new Map<Theme, ThemeConfig>([
    [Theme.IW, Utils.themeConfigIw],
    [Theme.IN, Utils.themeConfigIn]
  ]);

  static resolveTheme(): Theme {
    const themes = Object.values(Theme);
    const themeFromHostname = themes.find(themeName =>
      window.location.hostname.toLowerCase().includes(themeName)
    );
    return themeFromHostname || Theme.IW;
  }

  static resolveThemeConfig(theme: Theme): ThemeConfig | undefined {
    return this.themeConfigMap.get(theme);
  }
}
