













import { sdButton } from '@smartdesign/vue/components/button';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const application = namespace('application');
const tracking = namespace('tracking');
@Component({
  components: {
    sdButton
  }
})
export default class NewValuationButton extends Vue {
  @application.Getter
  getValuationRangeUrl!: () => string;

  @tracking.Action
  trackLink!: (eventLabel: string) => void;
}
