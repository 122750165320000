import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ValuationRangeLeadDto } from './domain/valuation-range-lead.dto';

export default class Exchange {
  static async callValuationRangeLeadApplication(
    dto: ValuationRangeLeadDto,
    oAuthToken: string
  ): Promise<AxiosResponse> {
    const requestConfig: AxiosRequestConfig = {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${oAuthToken}`,
        crossdomain: true,
        'Cache-Control': 'no-cache'
      },
      baseURL: process.env.VUE_APP_VALUATION_RANGE_PROXY
    };
    return axios.post('/v1/lead-request', dto, requestConfig);
  }
}
