








import { Component, Vue } from 'vue-property-decorator';
import { sdCol, sdRow } from '@smartdesign/vue/components/grid/';
import { namespace } from 'vuex-class';

const application = namespace('application');

@Component({
  components: {
    sdCol,
    sdRow
  }
})
export default class ConsentText extends Vue {
  @application.Getter
  getConsentText!: string;
}
