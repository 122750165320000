




















import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Estimation } from '../../../store/estimation/domain/models/estimation.interface';
import SkeletonLoader from '../../common-ui/SkeletonLoader.vue';

const estimation = namespace('estimation');
@Component({
  components: {
    SkeletonLoader
  }
})
export default class EstimationBar extends Vue {
  @estimation.Getter
  getEstimation!: () => Estimation;
}
