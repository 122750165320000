








































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Property } from '../../../store/estimation/domain/models/property.interface';
import { ObjectType } from '../../../store/estimation/domain/models/objecttype.enum';
import SkeletonLoader from '../../common-ui/SkeletonLoader.vue';

const estimation = namespace('estimation');
@Component({
  components: {
    SkeletonLoader
  }
})
export default class PropertySummary extends Vue {
  @estimation.Getter
  getProperty!: Property;

  ObjectType = ObjectType;

  get formattedAddress(): string {
    const { address } = this.getProperty;
    return `${address.street} ${address.houseNumber}, ${address.zip} ${address.town}`;
  }
}
