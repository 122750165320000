






















import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Broker } from '../../../store/valuation/domain/broker.interface';
import sdTextlist from '@smartdesign/vue/components/textlist';

const valuation = namespace('valuation');
@Component({
  components: {
    sdTextlist
  }
})
export default class BrokerList extends Vue {
  private icons: Array<string> = ['number_one', 'number_two', 'number_three'];

  @valuation.Getter
  getBrokers!: Array<Broker>;

  @valuation.Getter
  getIsDoubletLead!: boolean;
}
