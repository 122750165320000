import { Module } from 'vuex';
import UserState from './user-state.interface';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import { RootState } from '../root-state';

export const state: UserState = { userOAuthToken: undefined, globalUserId: undefined };

const namespaced = true;

export const user: Module<UserState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters
};
