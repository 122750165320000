














import { Component, Vue } from 'vue-property-decorator';
import BaseHeadline from '../../../common-ui/BaseHeadline.vue';
import SubcontextContainer from '../../../layout/SubcontextContainer.vue';
import BrokerList from '../BrokerList.vue';

@Component({
  components: {
    BaseHeadline,
    SubcontextContainer,
    BrokerList
  }
})
export default class ValuationSuccessModule extends Vue {}
