






import { Component, Vue } from 'vue-property-decorator';
import ContentContainer from './components/layout/BaseViewWrapper.vue';
import { namespace } from 'vuex-class';
import { Theme } from './store/application/domain/theme.enum';
import { VueConstructor } from 'vue';

const user = namespace('user');
const application = namespace('application');
const estimation = namespace('estimation');
import { TrackingViewType } from './store/tracking/domain/trackingviewtype.enum';

const tracking = namespace('tracking');
@Component<App>({
  components: {
    ContentContainer
  },
  metaInfo() {
    return {
      script: [
        {
          src: this.getNavigationPath
        },
        {
          // prevent default initial pageView - see https://docs.tealium.com/platforms/javascript/single-page-applications/
          innerHTML:
            'window.utag_cfg_ovrd = window.utag_cfg_ovrd || {}; window.utag_cfg_ovrd.noview = true;'
        },
        {
          innerHTML: `window.utag_data = { app_medienid: ${this.getMediaId} };`
        },
        {
          src: process.env.VUE_APP_TEALIUM
        }
      ],
      title: `Preis-Update - Aktuelle Preise für Deine Immobilie auf ${this.getTheme}.de`
    };
  }
})
export default class App extends Vue {
  @user.Action
  handleUserLoginStatus!: () => void;

  @application.Action
  handleTheming!: () => void;

  @estimation.Action
  handleInitialEstimation!: (objectId: number) => void;

  @application.Getter
  getNavigationPath!: () => string;

  @application.Getter
  getMediaId!: () => string;

  @application.Getter
  getTheme!: () => Theme;

  @application.Getter
  getCurrentView!: () => VueConstructor;

  @tracking.Action
  trackView!: (trackingViewType: TrackingViewType) => void;

  async created(): Promise<void> {
    this.handleTheming();
  }

  async mounted(): Promise<void> {
    await this.handleUserLoginStatus();
    await this.handleInitialEstimation(parseInt(this.$route.query.objectId as string, 10));
    this.trackView(TrackingViewType.ESTIMATION);
  }
}
