import { GetterTree } from 'vuex';
import { RootState } from '../root-state';
import EstimationState from './estimation-state.interface';
import { Estimation } from './domain/models/estimation.interface';
import { Property } from './domain/models/property.interface';
import { LeadcontactPropertyEstimationDto } from './domain/models/leadcontact-property-estimation.dto';

const getters: GetterTree<EstimationState, RootState> = {
  getEstimationIsReady(state): boolean {
    return state.estimationIsReady;
  },
  getEstimationIsUpdated(state): boolean {
    return state.estimationIsUpdated;
  },
  getEstimation(state): Estimation | undefined {
    return state.estimation;
  },
  getProperty(state): Property | undefined {
    return state.property;
  },
  getObjectId(state): number | undefined {
    return state.objectId;
  },
  getLeadcontactPropertyEstimationDto(state): LeadcontactPropertyEstimationDto | undefined {
    return state.leadcontactPropertyEstimationDto;
  }
};

export default getters;
