














import { Component, Vue } from 'vue-property-decorator';
import { sdContainer } from '@smartdesign/vue/components/grid/';

@Component({
  components: {
    sdContainer
  }
})
export default class BaseViewWrapper extends Vue {}
