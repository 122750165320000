import Vue from 'vue';
import VueRouter, { RouteConfig, RouterMode } from 'vue-router';
import App from '../App.vue';

const mode: RouterMode = 'history';
const base = '/';
const routes: Array<RouteConfig> = [{ path: '/', component: App }];

Vue.use(VueRouter);

export default new VueRouter({
  mode,
  base,
  routes
});
