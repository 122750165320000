import { ActionTree } from 'vuex';
import { Utag } from './domain/utag.type';
import { RootState } from '../root-state';
import { LeadcontactPropertyEstimationDto } from '../estimation/domain/models/leadcontact-property-estimation.dto';
import Utils from './utils';
import { TrackingViewType } from './domain/trackingviewtype.enum';

declare const utag: Utag | undefined;

const actions: ActionTree<RootState, RootState> = {
  async trackLink(_actionContext, eventLabel: string): Promise<void> {
    if (utag) {
      const trackingData = Utils.buildTrackLinkData(eventLabel);
      utag.link(trackingData);
    }
  },

  async trackView({ rootGetters }, trackingViewType: TrackingViewType): Promise<void> {
    if (utag) {
      const leadcontactPropertyEstimationDto: LeadcontactPropertyEstimationDto =
        rootGetters['estimation/getLeadcontactPropertyEstimationDto'];
      const mediaId: string = rootGetters['application/getMediaId'];

      const sharedTrackingData = Utils.getSharedTrackingData(mediaId);

      const viewSpecificTrackingData = Utils.getTrackingDataByViewType(
        leadcontactPropertyEstimationDto,
        trackingViewType
      );
      const consolidatedTrackingData = {
        ...sharedTrackingData,
        ...viewSpecificTrackingData
      };

      if (viewSpecificTrackingData) {
        utag.view(consolidatedTrackingData);
      }
    }
  }
};

export default actions;
