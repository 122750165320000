import Vue from 'vue';
import Meta from 'vue-meta';
import App from './App.vue';
import store from './store';
import router from './router';
import '@smartdesign/core/css/main.css';

Vue.use(Meta);
Vue.config.productionTip = false;
Vue.config.ignoredElements = ['navigation-ui-header', 'navigation-ui-footer'];

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app');
