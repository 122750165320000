import { GetterTree } from 'vuex';
import { RootState } from '../root-state';
import ValuationState from './valuation-state.interface';
import { Broker } from './domain/broker.interface';

const getters: GetterTree<ValuationState, RootState> = {
  getPhonenumber(state): string {
    return state.phonenumber;
  },

  getPhonenumberIsValid(state): boolean {
    return state.phonenumberIsValid;
  },

  getShowErrorMessage(state): boolean {
    return state.showErrorMessage;
  },

  getShowValuation(state): boolean {
    return state.showValuation;
  },

  getBrokers(state): Array<Broker> {
    return state.brokers;
  },

  getIsDoubletLead(state): boolean {
    return state.isDoubletLead;
  }
};

export default getters;
