import MD5 from 'crypto-js/md5';
import { LeadcontactPropertyEstimationDto } from '../estimation/domain/models/leadcontact-property-estimation.dto';
import { TrackingViewType } from './domain/trackingviewtype.enum';

export default class Utils {
  static buildTrackLinkData(eventLabel: string): Record<string, unknown> {
    return {
      event_category: 'price_update',
      event_action: 'click',
      event_label: eventLabel
    };
  }

  static getSharedTrackingData(mediaId: string): Record<string, unknown> {
    return {
      app_medienid: mediaId,
      app_server: 'EST',
      app_time: Date.now(),
      customer_is_domestic: '0',
      customer_type: '-'
    };
  }

  static getTrackingDataByViewType(
    leadcontactPropertyEstimationDto: LeadcontactPropertyEstimationDto,
    trackingViewType: TrackingViewType
  ): Record<string, unknown> | undefined {
    if (trackingViewType === TrackingViewType.ESTIMATION) {
      return this.getTrackingDataEstimation(leadcontactPropertyEstimationDto);
    }
    if (trackingViewType === TrackingViewType.VALUATION_REQUEST) {
      return this.getTrackingDataValuationRequest();
    }
    if (trackingViewType === TrackingViewType.VALUATION_SUCESS) {
      return this.getTrackingDataValuationSuccess(leadcontactPropertyEstimationDto);
    }
    return undefined;
  }

  private static getTrackingDataEstimation(
    leadcontactPropertyEstimationDto: LeadcontactPropertyEstimationDto
  ): Record<string, unknown> {
    return {
      customer_idkey_map: MD5(leadcontactPropertyEstimationDto.contact.email).toString(),
      enh_action: 'detail',
      object_marketingtype: [leadcontactPropertyEstimationDto.marketingType],
      object_usergroup: [leadcontactPropertyEstimationDto.usergroup],
      page_name: 'anbieten/immobilienbewertung/preisupdate',
      page_type: 'productdetailpage',
      product_name: ['kostenlose_immobilienbewertung'],
      product_id: ['526'],
      product_quantity: ['1'],
      product_variant: ['price_update']
    };
  }

  private static getTrackingDataValuationRequest(): Record<string, unknown> {
    return {
      page_name: 'anbieten/immobilienbewertung/preisupdate/phonenumber'
    };
  }

  private static getTrackingDataValuationSuccess(
    leadcontactPropertyEstimationDto: LeadcontactPropertyEstimationDto
  ): Record<string, unknown> {
    return {
      enh_action: 'purchase',
      object_marketingtype: [leadcontactPropertyEstimationDto.marketingType],
      object_usergroup: [leadcontactPropertyEstimationDto.usergroup],
      page_name: 'anbieten/immobilienbewertung/preisupdate/thankyoupage',
      page_type: 'thankyoupage',
      product_name: ['kostenlose_immobilienbewertung'],
      product_id: ['526'],
      product_order_id: ['526/1576675328155'],
      product_quantity: ['1'],
      product_variant: ['price_update']
    };
  }
}
