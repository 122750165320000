




import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SkeletonLoader extends Vue {
  @Prop({ default: true }) readonly fullwidth!: boolean;

  private readonly minWidth = 51;

  private readonly maxWidth = 75;

  randomWidth(): number {
    return Math.floor(Math.random() * (this.maxWidth - this.minWidth + 1)) + this.minWidth;
  }
}
