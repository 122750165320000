

















import { sdButton } from '@smartdesign/vue/components/button';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { VueConstructor } from 'vue';
import ValuationRequest from '../../../views/ValuationRequest.vue';

const application = namespace('application');
const estimation = namespace('estimation');
const tracking = namespace('tracking');
@Component({
  components: {
    sdButton
  }
})
export default class ValuationButton extends Vue {
  private ValuationRequest = ValuationRequest;

  @estimation.Getter
  getEstimationIsReady!: () => boolean;

  @application.Mutation
  setCurrentView!: (view: VueConstructor) => void;

  @tracking.Action
  trackLink!: (eventLabel: string) => void;
}
