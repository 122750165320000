import { Module } from 'vuex';
import actions from './actions';
import { RootState } from '../root-state';

const namespaced = true;

export const tracking: Module<RootState, RootState> = {
  namespaced,
  actions
};
