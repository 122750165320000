import { ActionTree } from 'vuex';
import ValuationState from './valuation-state.interface';
import { RootState } from '../root-state';
import { LeadcontactPropertyEstimationDto } from '../estimation/domain/models/leadcontact-property-estimation.dto';
import Utils from './utils';
import Exchange from './exchange';
import ValuationSuccess from '../../views/ValuationSuccess.vue';
import { MarketingType } from './domain/marketing-type.enum';
import { UserGroup } from './domain/user-group.enum';
import { ValuationRangeLeadDto } from './domain/valuation-range-lead.dto';

const actions: ActionTree<ValuationState, RootState> = {
  validatePhonenumber({ commit, rootGetters }): void {
    const phonenumber: string = rootGetters['valuation/getPhonenumber'];
    const isANumber = (value: string) => value.search(/^[0-9]+$/) === 0;
    const isValid = phonenumber.length >= 11 && isANumber(phonenumber);
    commit('setPhonenumberIsValid', isValid);
    commit('setShowErrorMessage', !isValid);
  },

  async handleValuation({ commit, rootGetters }): Promise<void> {
    try {
      const consentText: string = rootGetters['application/getConsentText'];
      const phonenumber: string = rootGetters['valuation/getPhonenumber'];
      const oAuthToken: string = rootGetters['user/getOAuthToken'];
      const dto: LeadcontactPropertyEstimationDto =
        rootGetters['estimation/getLeadcontactPropertyEstimationDto'];
      dto.contact.phone = phonenumber;
      // from BUY to SELL when user enters phone number
      dto.marketingType = MarketingType.SELL;
      dto.usergroup = UserGroup.OWNER;
      const valuationResponse = await Exchange.callValuationRangeLeadApplication(
        ValuationRangeLeadDto.fromLeadContactPropertyEstimationDto(dto, consentText),
        oAuthToken
      );
      const { agents: brokers, doublet } = valuationResponse.data;
      commit('setBrokers', brokers);
      commit('setIsLeadDoublet', doublet);
      commit('application/setCurrentView', ValuationSuccess, { root: true });
    } catch (e) {
      console.error('error at getting valuation:', e);
    }
  },

  handleValuationDisplay({ commit }, dto: LeadcontactPropertyEstimationDto): void {
    const showValuation = Utils.resolveValuationDisplayCondition(dto);
    commit('setShowValuation', showValuation);
  }
};
export default actions;
