import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './root-state';
import { user } from './user';
import { application } from './application';
import { estimation } from './estimation';
import { valuation } from './valuation';
import { tracking } from './tracking';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  modules: {
    application,
    user,
    estimation,
    valuation,
    tracking
  }
};
export default new Vuex.Store<RootState>(store);
