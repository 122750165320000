












import { Component, Vue } from 'vue-property-decorator';
import BaseViewWrapper from '../components/layout/BaseViewWrapper.vue';
import EstimationModule from '../components/domain/estimation/module/EstimationModule.vue';
import PropertyModule from '../components/domain/property/module/PropertyModule.vue';
import ValuationEntrypointModule from '../components/domain/valuation/module/ValuationEntrypointModule.vue';

@Component({
  components: {
    ValuationEntrypointModule,
    BaseViewWrapper,
    EstimationModule,
    PropertyModule
  }
})
export default class Index extends Vue {}
