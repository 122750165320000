import { ActionTree } from 'vuex';
import { RootState } from '../root-state';
import ApplicationState from './application-state.interface';
import { Utils } from './utils';
import { Theme } from './domain/theme.enum';

const actions: ActionTree<ApplicationState, RootState> = {
  handleTheming({ commit, dispatch }): void {
    const theme = Utils.resolveTheme();
    const { navigationPath, loginUrl, valuationRangeUrl, mediaId } = {
      ...Utils.resolveThemeConfig(theme)
    };

    commit('setTheme', theme);
    commit('setNavigationPath', navigationPath);
    commit('setLoginUrl', loginUrl);
    commit('setValuationRangeUrl', valuationRangeUrl);
    commit('setMediaId', mediaId);

    dispatch('importThemeStyles', theme);
    dispatch('setFavicon', theme);
  },
  importThemeStyles(_actionContext, theme: Theme): void {
    // this needs to be this clunky because import() cannot resolve a dynamically generated path
    if (theme === Theme.IN) {
      // eslint-disable-next-line no-unused-expressions
      import('@smartdesign/core/css/themes/in/index.css');
    } else {
      // eslint-disable-next-line no-unused-expressions
      import('@smartdesign/core/css/themes/iw/index.css');
    }
  },
  setFavicon(_actionContext, theme: Theme): void {
    const favicon = document.createElement('link');
    favicon.rel = 'icon';
    favicon.href = `/favicons/favicon-${theme}.ico`;
    document.head.appendChild(favicon);
  }
};
export default actions;
