import { GetterTree } from 'vuex';
import { RootState } from '../root-state';
import UserState from './user-state.interface';

const getters: GetterTree<UserState, RootState> = {
  getOAuthToken(state): string | undefined {
    return state.userOAuthToken;
  },
  getGlobalUserId(state): number | undefined {
    return state.globalUserId;
  }
};

export default getters;
