import { MutationTree } from 'vuex';
import EstimationState from './estimation-state.interface';
import { Estimation } from './domain/models/estimation.interface';
import { Property } from './domain/models/property.interface';
import { LeadcontactPropertyEstimationDto } from './domain/models/leadcontact-property-estimation.dto';

const mutations: MutationTree<EstimationState> = {
  setEstimationIsReady(state, isReady: boolean): void {
    state.estimationIsReady = isReady;
  },
  setEstimationIsUpdated(state, isUpdated: boolean): void {
    state.estimationIsUpdated = isUpdated;
  },
  setEstimation(state, estimation: Estimation): void {
    state.estimation = estimation;
  },
  setProperty(state, property: Property): void {
    state.property = property;
  },
  setObjectId(state, objectId: number): void {
    state.objectId = objectId;
  },
  setLeadcontactPropertyEstimationDto(
    state,
    leadcontactPropertyEstimationDto: LeadcontactPropertyEstimationDto
  ): void {
    state.leadcontactPropertyEstimationDto = leadcontactPropertyEstimationDto;
  }
};

export default mutations;
