



















import { sdButton } from '@smartdesign/vue/components/button';
import { sdAlertbox } from '@smartdesign/vue/components/alertbox';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const estimation = namespace('estimation');
const valuation = namespace('valuation');
const tracking = namespace('tracking');
@Component({
  components: {
    sdButton,
    sdAlertbox
  }
})
export default class EstimationButton extends Vue {
  @valuation.Getter
  getShowValuation!: () => boolean;

  @estimation.Getter
  getEstimationIsReady!: () => boolean;

  @estimation.Getter
  getEstimationIsUpdated!: () => boolean;

  @estimation.Action
  handleUpdatedEstimation!: () => void;

  @tracking.Action
  trackLink!: (eventLabel: string) => void;

  private updateEstimation(): void {
    this.handleUpdatedEstimation();
    this.trackLink('schaetzung aktualisieren');
  }
}
