import { MutationTree } from 'vuex';
import ApplicationState from './application-state.interface';
import { Theme } from './domain/theme.enum';
import { VueConstructor } from 'vue';

const mutations: MutationTree<ApplicationState> = {
  setTheme(state, theme: Theme): void {
    state.theme = theme;
  },
  setNavigationPath(state, navigationPath: string): void {
    state.navigationPath = navigationPath;
  },
  setLoginUrl(state, loginUrl: string): void {
    state.loginUrl = loginUrl;
  },
  setValuationRangeUrl(state, valuationRangeUrl: string): void {
    state.valuationRangeUrl = valuationRangeUrl;
  },
  setMediaId(state, mediaId: string): void {
    state.mediaId = mediaId;
  },
  setCurrentView(state, view: VueConstructor): void {
    state.currentView = view;
  }
};

export default mutations;
