import { Address } from '../../estimation/domain/models/address.interface';
import { GeoCoordinates } from '../../estimation/domain/models/geocoordinates.interface';
import { LeadcontactPropertyEstimationDto } from '../../estimation/domain/models/leadcontact-property-estimation.dto';
import {
  valuationRangeLeadContactFromContact,
  ValuationRangeLeadContact
} from './valuation-range-lead-contact.interface';

export class ValuationRangeLeadDto {
  address: Address;

  constructionYear: number;

  contact: ValuationRangeLeadContact;

  plotArea: number;

  livingArea: number;

  rooms: number;

  marketingType: string;

  objectType: string;

  origin: string;

  userGroup: string;

  parking: string;

  floor: number;

  elevator: boolean;

  condominiumFee: number;

  objectSubType: string;

  iwtLocationId: number;

  asgAddressId: number;

  asgCityId: number;

  asgRegionId: number;

  geoCoordinates: GeoCoordinates;

  consentText: string;

  static fromLeadContactPropertyEstimationDto(
    leadContactPropertyEstimationDto: LeadcontactPropertyEstimationDto,
    consentText: string
  ): ValuationRangeLeadDto {
    return {
      address: leadContactPropertyEstimationDto.address,
      constructionYear: leadContactPropertyEstimationDto.constructionYear,
      contact: valuationRangeLeadContactFromContact(leadContactPropertyEstimationDto.contact),
      plotArea: leadContactPropertyEstimationDto.plotArea,
      livingArea: leadContactPropertyEstimationDto.livingArea,
      rooms: leadContactPropertyEstimationDto.rooms,
      marketingType: leadContactPropertyEstimationDto.marketingType,
      objectType: leadContactPropertyEstimationDto.objectType,
      origin: leadContactPropertyEstimationDto.origin,
      userGroup: leadContactPropertyEstimationDto.usergroup,
      parking: `${leadContactPropertyEstimationDto.parking}`,
      floor: leadContactPropertyEstimationDto.floor,
      elevator: leadContactPropertyEstimationDto.elevator,
      condominiumFee: leadContactPropertyEstimationDto.condominiumFee,
      objectSubType: leadContactPropertyEstimationDto.objectSubType,
      iwtLocationId: leadContactPropertyEstimationDto.locationId,
      asgAddressId: leadContactPropertyEstimationDto.addressId,
      asgCityId: leadContactPropertyEstimationDto.cityId,
      asgRegionId: leadContactPropertyEstimationDto.regionId,
      geoCoordinates: {
        longitude:
          typeof leadContactPropertyEstimationDto.geoCoordinates.longitude === 'string'
            ? parseInt(leadContactPropertyEstimationDto.geoCoordinates.longitude, 10)
            : leadContactPropertyEstimationDto.geoCoordinates.longitude,
        latitude:
          typeof leadContactPropertyEstimationDto.geoCoordinates.latitude === 'string'
            ? parseInt(leadContactPropertyEstimationDto.geoCoordinates.latitude, 10)
            : leadContactPropertyEstimationDto.geoCoordinates.latitude
      },
      consentText
    };
  }
}
