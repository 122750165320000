
















import { sdButton } from '@smartdesign/vue/components/button';
import { sdAlertbox } from '@smartdesign/vue/components/alertbox';
import { Component, Vue } from 'vue-property-decorator';
import SubcontextContainer from '../../../layout/SubcontextContainer.vue';
import BaseHeadline from '../../../common-ui/BaseHeadline.vue';
import PropertySummary from '../PropertySummary.vue';
import NewValuationButton from '../NewValuationButton.vue';

@Component({
  components: {
    NewValuationButton,
    PropertySummary,
    BaseHeadline,
    SubcontextContainer,
    sdButton,
    sdAlertbox
  }
})
export default class PropertyModule extends Vue {}
