import { ActionTree } from 'vuex';
import { RootState } from '../root-state';
import qs from 'qs';
import Cookies from 'js-cookie';
import UserState from './user-state.interface';
import Exchange from './exchange';
import Router from '../../router';

const actions: ActionTree<UserState, RootState> = {
  async handleUserLoginStatus({ commit, dispatch }): Promise<void> {
    const userOAuthToken = Cookies.get('oauth.access.token');
    if (!userOAuthToken) {
      dispatch('redirectUserToLogin');
    } else {
      await dispatch('retrieveUserInformation', userOAuthToken);
      commit('setUserOAuthToken', userOAuthToken);
    }
  },

  async retrieveUserInformation({ commit, dispatch }, oAuthToken: string): Promise<void> {
    try {
      const { data: userInformation } = await Exchange.callUserAuthServiceInfo(oAuthToken);
      commit('setGlobalUserId', userInformation.globalUserId);
    } catch (e) {
      console.error('Error while retrieving User Info:', e);
      dispatch('redirectUserToLogin');
    }
  },

  redirectUserToLogin({ rootGetters }): void {
    const queryString = qs.stringify(Router.currentRoute.query, { addQueryPrefix: true });
    window.location.href = `${rootGetters['application/getLoginUrl']}${escape(queryString)}`;
  }
};
export default actions;
