






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class BaseHeadline extends Vue {
  @Prop({ default: false }) readonly isPageHeader!: boolean;
}
