import { Property } from './models/property.interface';
import { LeadcontactPropertyEstimationDto } from './models/leadcontact-property-estimation.dto';
import { HouseSubType } from './models/house-subtype.enum';
import { ApartmentSubType } from './models/apartment-subtype.enum';

export class PropertyMapper {
  private static readonly humanReadableObjectSubtypeMap = new Map<string, string>([
    [ApartmentSubType.APARTMENT, 'Wohnung'],
    [ApartmentSubType.DUPLEX_OR_TRIPLEX, 'Maisonette'],
    [ApartmentSubType.PENTHOUSE, 'Penthouse'],
    [HouseSubType.SINGLE_FAMILY_HOUSE, 'Einfamilienhaus'],
    [HouseSubType.MULTI_FAMILY_HOUSE, 'Mehrfamilienhaus'],
    [HouseSubType.END_TERRACE_HOUSE, 'Reihenendhaus'],
    [HouseSubType.MID_TERRACE_HOUSE, 'Reihenhaus'],
    [HouseSubType.SEMI_DETACHED_HOUSE, 'Doppelhaushälfte']
  ]);

  static mapToProperty(leadcontactDto: LeadcontactPropertyEstimationDto): Property {
    return (({
      livingArea,
      rooms,
      parking,
      constructionYear,
      address,
      objectSubType,
      objectType,
      plotArea,
      condominiumFee,
      floor,
      elevator
    }): Property => ({
      livingArea,
      rooms,
      parking,
      constructionYear,
      address,
      objectSubType: this.humanReadableObjectSubtypeMap.get(objectSubType) || '',
      objectType,
      plotArea,
      condominiumFee,
      floor,
      elevator
    }))(leadcontactDto);
  }
}
