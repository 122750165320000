import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export default class Exchange {
  static async callUserAuthServiceInfo(oAuthToken: string): Promise<AxiosResponse> {
    const requestConfig: AxiosRequestConfig = {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${oAuthToken}`
      }
    };
    return axios.get(process.env.VUE_APP_USERAUTHSERVICE_INFO_URL, requestConfig);
  }
}
