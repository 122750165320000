









import { Component, Vue } from 'vue-property-decorator';
import BaseViewWrapper from '../components/layout/BaseViewWrapper.vue';
import PropertyModule from '../components/domain/property/module/PropertyModule.vue';
import ValuationSuccessModule from '../components/domain/valuation/module/ValuationSuccessModule.vue';

import { namespace } from 'vuex-class';
import { TrackingViewType } from '../store/tracking/domain/trackingviewtype.enum';

const tracking = namespace('tracking');
@Component({
  components: {
    PropertyModule,
    BaseViewWrapper,
    ValuationSuccessModule
  }
})
export default class ValuationSuccess extends Vue {
  @tracking.Action
  trackView!: (trackingViewType: TrackingViewType) => void;

  mounted(): void {
    window.scrollTo(0, 0);
    this.trackView(TrackingViewType.VALUATION_SUCESS);
  }
}
