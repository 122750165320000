
















import sdTextfield from '@smartdesign/vue/components/textfield';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const valuation = namespace('valuation');
@Component({
  components: {
    sdTextfield
  }
})
export default class InputPhone extends Vue {
  @valuation.State
  private phonenumber!: string;

  @valuation.Mutation
  setPhonenumber!: (phonenumber: string) => void;

  @valuation.Action
  validatePhonenumber!: () => void;

  @valuation.Getter
  getPhonenumberIsValid!: boolean;

  @valuation.Getter
  getShowErrorMessage!: boolean;

  handleTyping(input: string): void {
    this.setPhonenumber(input);
    this.validatePhonenumber();
  }
}
