import { MutationTree } from 'vuex';
import ValuationState from './valuation-state.interface';
import { Broker } from './domain/broker.interface';

const mutations: MutationTree<ValuationState> = {
  setShowValuation(state, showValuation: boolean): void {
    state.showValuation = showValuation;
  },

  setPhonenumber(state, phonenumber: string): void {
    state.phonenumber = phonenumber;
  },

  setPhonenumberIsValid(state, phonenumberIsValid: boolean): void {
    state.phonenumberIsValid = phonenumberIsValid;
  },

  setShowErrorMessage(state, showErrorMessage: boolean): void {
    state.showErrorMessage = showErrorMessage;
  },

  setBrokers(state, brokers: Array<Broker>): void {
    state.brokers = brokers;
  },

  setIsLeadDoublet(state, isLeadDoublet): void {
    state.isDoubletLead = isLeadDoublet;
  }
};

export default mutations;
