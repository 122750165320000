import { Module } from 'vuex';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import { RootState } from '../root-state';
import EstimationState from './estimation-state.interface';

export const state: EstimationState = {
  estimationIsReady: false,
  estimationIsUpdated: false,
  estimation: undefined,
  property: undefined,
  objectId: undefined
};

const namespaced = true;

export const estimation: Module<EstimationState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters
};
