import { Module } from 'vuex';
import ApplicationState from './application-state.interface';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import { RootState } from '../root-state';
import Index from '../../views/Index.vue';

export const state: ApplicationState = {
  theme: undefined,
  navigationPath: undefined,
  loginUrl: undefined,
  currentView: Index
};

const namespaced = true;

export const application: Module<ApplicationState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters
};
