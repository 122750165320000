








import { sdButton } from '@smartdesign/vue/components/button';
import { Component, Vue } from 'vue-property-decorator';
import { VueConstructor } from 'vue';
import { namespace } from 'vuex-class';
import Index from '../../../views/Index.vue';

const application = namespace('application');
@Component({
  components: { sdButton }
})
export default class BackButton extends Vue {
  private Index = Index;

  @application.Mutation
  setCurrentView!: (view: VueConstructor) => void;
}
