






















import { Component, Vue } from 'vue-property-decorator';
import { sdCol, sdRow } from '@smartdesign/vue/components/grid/';

@Component({
  components: {
    sdCol,
    sdRow
  }
})
export default class SubcontextContainer extends Vue {}
