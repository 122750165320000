import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { LeadcontactPropertyEstimationDto } from './domain/models/leadcontact-property-estimation.dto';

export default class Exchange {
  static async callLeadcontactPropertyEstimationServiceGet(
    oAuthToken: string,
    globalUserId: number,
    objectId: number
  ): Promise<AxiosResponse> {
    const requestConfig: AxiosRequestConfig = {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${oAuthToken}`,
        crossdomain: true,
        'Cache-Control': 'no-cache'
      },
      baseURL: process.env.VUE_APP_LEADCONTACT_PROPERTY_ESTIMATION_SERVICE_URL
    };
    return axios.get(`/${globalUserId}/${objectId}/latest`, requestConfig);
  }

  static async callLeadcontactPropertyEstimationServiceUpdate(
    oAuthToken: string,
    objectId: number,
    leadcontactPropertyEstimationDto: LeadcontactPropertyEstimationDto
  ): Promise<AxiosResponse> {
    const requestConfig: AxiosRequestConfig = {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${oAuthToken}`,
        crossdomain: true,
        'Cache-Control': 'no-cache'
      },
      baseURL: process.env.VUE_APP_LEADCONTACT_PROPERTY_ESTIMATION_SERVICE_URL
    };
    return axios.post(
      `/${objectId}/estimation/new`,
      { ...leadcontactPropertyEstimationDto },
      requestConfig
    );
  }
}
