import { Module } from 'vuex';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import { RootState } from '../root-state';
import ValuationState from './valuation-state.interface';

export const state: ValuationState = {
  showValuation: true,
  phonenumber: '',
  phonenumberIsValid: false,
  showErrorMessage: false,
  brokers: [],
  isDoubletLead: false
};

const namespaced = true;

export const valuation: Module<ValuationState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters
};
