import { Contact } from '../../estimation/domain/models/contact.interface';

export interface ValuationRangeLeadContact {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  salutation: string;
  reachability?: Array<string>;
  timeOfSale: string;
}

export const valuationRangeLeadContactFromContact = (
  contact: Contact
): ValuationRangeLeadContact => ({
  ...contact,
  reachability: contact.reachability ? [contact.reachability] : undefined
});
