import { GetterTree } from 'vuex';
import { RootState } from '../root-state';
import ApplicationState from './application-state.interface';
import { Theme } from './domain/theme.enum';
import { VueConstructor } from 'vue';

const getters: GetterTree<ApplicationState, RootState> = {
  getTheme(state): Theme | undefined {
    return state.theme;
  },
  getNavigationPath(state): string | undefined {
    return state.navigationPath;
  },
  getLoginUrl(state): string | undefined {
    return state.loginUrl;
  },
  getValuationRangeUrl(state): string | undefined {
    return state.valuationRangeUrl;
  },
  getMediaId(state): string | undefined {
    return state.mediaId;
  },
  getCurrentView(state): VueConstructor {
    return state.currentView;
  },
  getConsentText(): string {
    return 'Deine Angaben werden zur Kontaktaufnahme durch die immowelt GmbH genutzt sowie an unsere Partner weitergegeben. Bei unseren Partnern handelt es sich um bis zu drei Immobilienexperten, die in deiner Region (PLZ-Gebiet) ihre Dienstleistungen anbieten und mit der immowelt GmbH kooperieren. Mit deiner Anfrage nimmst du den immowelt Service in Anspruch. Du erhältst anhand der von dir eingegebenen Daten und genutzten Services und auf Grundlage unseres Geschäftszwecks auf dein Anliegen ausgerichtete Informationen. Der Verwendung deiner Daten kannst du jederzeit unter <a href="mailto:datenschutz@immowelt.de">datenschutz@immowelt.de</a> widersprechen. Weitere Informationen findest du in der <a href="https://immowelt.de/immoweltag/datenschutz">Datenschutzerklärung</a>.';
  }
};

export default getters;
