import { MutationTree } from 'vuex';
import UserState from './user-state.interface';

const mutations: MutationTree<UserState> = {
  setUserOAuthToken(state, userOAuthToken: string): void {
    state.userOAuthToken = userOAuthToken;
  },
  setGlobalUserId(state, globalUserId: number): void {
    state.globalUserId = globalUserId;
  }
};

export default mutations;
