import { ActionTree } from 'vuex';
import { RootState } from '../root-state';
import EstimationState from './estimation-state.interface';
import Exchange from './exchange';
import { PropertyMapper } from './domain/property-mapper';
import { LeadcontactPropertyEstimationDto } from './domain/models/leadcontact-property-estimation.dto';

const actions: ActionTree<EstimationState, RootState> = {
  async handleInitialEstimation(
    { commit, rootGetters, dispatch },
    objectId: number
  ): Promise<void> {
    if (objectId && !Number.isNaN(objectId)) {
      const globalUserId: number = rootGetters['user/getGlobalUserId'];
      const oAuthToken: string = rootGetters['user/getOAuthToken'];
      try {
        const { data: leadcontactPropertyEstimationDto } =
          await Exchange.callLeadcontactPropertyEstimationServiceGet(
            oAuthToken,
            globalUserId,
            objectId
          );
        const { estimation } = leadcontactPropertyEstimationDto;
        const property = PropertyMapper.mapToProperty(leadcontactPropertyEstimationDto);

        dispatch('valuation/handleValuationDisplay', leadcontactPropertyEstimationDto, {
          root: true
        });
        commit('setObjectId', objectId);
        commit('setEstimation', estimation);
        commit('setProperty', property);
        commit('setEstimationIsReady', true);
        commit('setLeadcontactPropertyEstimationDto', leadcontactPropertyEstimationDto);
      } catch (e) {
        console.error('error at getting estimation:', e);
      }
    }
  },
  async handleUpdatedEstimation({ commit, rootGetters }): Promise<void> {
    commit('setEstimation', undefined);
    commit('setEstimationIsReady', false);

    const oAuthToken: string = rootGetters['user/getOAuthToken'];
    const objectId: number = rootGetters['estimation/getObjectId'];
    const leadcontactPropertyEstimationDto: LeadcontactPropertyEstimationDto =
      rootGetters['estimation/getLeadcontactPropertyEstimationDto'];
    try {
      const { data: newLeadcontactPropertyEstimationDto } =
        await Exchange.callLeadcontactPropertyEstimationServiceUpdate(
          oAuthToken,
          objectId,
          leadcontactPropertyEstimationDto
        );

      const { estimation } = newLeadcontactPropertyEstimationDto;
      commit('setEstimation', estimation);
      commit('setEstimationIsReady', true);
      commit('setEstimationIsUpdated', true);
    } catch (e) {
      console.error('error at updating estimation:', e);
    }
  }
};
export default actions;
