



















import { sdButton } from '@smartdesign/vue/components/button';
import { sdAlertbox } from '@smartdesign/vue/components/alertbox';
import { Component, Vue } from 'vue-property-decorator';
import SubcontextContainer from '../../../layout/SubcontextContainer.vue';
import BaseHeadline from '../../../common-ui/BaseHeadline.vue';
import ValuationButton from '../ValuationButton.vue';
import { namespace } from 'vuex-class';

const valuation = namespace('valuation');
@Component({
  components: {
    ValuationButton,
    BaseHeadline,
    SubcontextContainer,
    sdButton,
    sdAlertbox
  }
})
export default class ValuationEntrypointModule extends Vue {
  @valuation.Getter
  getShowValuation!: () => boolean;
}
